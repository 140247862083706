import React from 'react';
import { View, Text, Link, Button, HeaderText } from '../components/common';
import Layout from '../components/layout';
import Content from '../components/content';
import CenteredMessage from '../components/CenteredMessage';
import { colors } from '../style';
import useragent from '../util/user-agent';

let appStoreUrl =
  'https://itunes.apple.com/us/app/actual-budget-your-finances/id1444818585';

let _agent;
let getAgent = () => {
  if (_agent == null) {
    _agent = useragent.parse(window.navigator.userAgent);
  }
  return _agent;
};

function onDownload({ isArm64 } = {}) {
  if (getAgent().isiPhone || getAgent().isiPad) {
    // Take them to the app store
    window.location.href = appStoreUrl;
  } else {
    let platform;
    if (getAgent().isMac) {
      if (isArm64) {
        platform = 'dmg-arm64';
      } else {
        platform = 'dmg';
      }
    } else if (getAgent().isWindows) {
      platform = 'exe';
    } else if (getAgent().isLinux || getAgent().isLinux64) {
      platform = 'AppImage';
    }

    window.location.href = `https://download.actualbudget.com/download/${platform}`;
  }
}

class DownloadPage extends React.Component {
  state = { macOptions: false, isBrowser: false };

  componentDidMount() {
    this.setState({ isBrowser: true });
  }

  showMacOptions = () => {
    this.setState({ macOptions: true });
  };

  renderWindowsWarning() {
    return (
      <View
        style={{
          backgroundColor: colors.y10,
          fontSize: '.85em',
          textAlign: 'left',
          padding: '10px 14px',
          borderRadius: 6,
          marginTop: 20
        }}
      >
        <Text>
          <strong>Windows users:</strong> you may see a SmartScreen warning when
          opening the app. We recently renewed the certificate and it{"'"}s
          building up trust again. This should not last for long.
        </Text>
      </View>
    );
  }

  renderSubscribed() {
    let { macOptions, isBrowser } = this.state;

    return (
      <React.Fragment>
        <HeaderText
          style={{ alignSelf: 'center', marginTop: 50, marginBottom: 30 }}
        >
          Thanks for subscribing!
        </HeaderText>
        {isBrowser && (
          <CenteredMessage className="text-lg" style={{ marginBottom: 60 }}>
            <View style={{ alignItems: 'center' }}>
              <View style={{ marginBottom: 25 }}>
                We offer both a web and desktop app. If you don't want to download the app, you can get started right here on the web.
              </View>

              <View style={{marginBottom: 25}}>
                <Button primary onClick={() => (window.location = "https://app.actualbudget.com")}>Use the web version</Button>
                </View>

              {macOptions ? (
                <View
                  style={{
                    backgroundColor: colors.n10,
                    padding: 15,
                    borderRadius: 4
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    Select the version that works best for your Mac
                  </Text>
                  <Text
                    style={{ marginBottom: 15, fontSize: 15, color: colors.n3 }}
                  >
                    Intel will work on all macs. The Apple M1 version will only
                    work on newer macs with that chip, but it will perform much
                    better.
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      fontSize: 15
                    }}
                  >
                    <Button
                      primary
                      onClick={() => onDownload()}
                      style={{ marginRight: 10 }}
                    >
                      Intel (x64)
                    </Button>
                    <Button
                      primary
                      onClick={() => onDownload({ isArm64: true })}
                    >
                      Apple M1 (arm64)
                    </Button>
                  </View>
                </View>
              ) : (
                <Button
                  primary
                  onClick={getAgent().isMac ? this.showMacOptions : onDownload}
                >
                  Download App
                </Button>
              )}

              {getAgent().isWindows && this.renderWindowsWarning()}
            </View>
          </CenteredMessage>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <Layout>
        <Content>
          {this.renderSubscribed()}
          <CenteredMessage>
            <View>
              <Text className="text-gray-700">
                Contact{' '}
                <Link external to="mailto:help@actualbudget.com">
                  help@actualbudget.com
                </Link>{' '}
                for support
              </Text>
            </View>

            <View className="mt-4">
              <Text>
                Join the community:{' '}
                <Link external to="https://slack.actualbudget.com">
                  Slack
                </Link>{' '}
                and{' '}
                <Link external to="https://reddit.com/r/actualbudget">
                  Reddit
                </Link>
                <View style={{ marginTop: 20 }}>
                  <Text style={{ fontSize: 13, color: colors.n4 }}>
                    Download other platforms{' '}
                    <Link to="/download-platforms/">here</Link>.
                  </Text>
                </View>
              </Text>
            </View>
          </CenteredMessage>
        </Content>
      </Layout>
    );
  }
}

export default DownloadPage;
