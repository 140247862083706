import React from 'react';
import { Text } from './common';
import { SMALL } from './content';

export const CenteredMessage = React.forwardRef((props, ref) => {
  return (
    <Text
      ref={ref}
      {...props}
      style={[
        {
          width: 500,
          margin: '0 auto',
          textAlign: 'center',
          [SMALL]: { width: 'auto', padding: 15 }
        },
        props.style
      ]}
    />
  );
});

export default CenteredMessage;
